<template>
  <div id="app" :class="appLang" @mousewheel="onMousewheel" ref="app">
    <Header :showNav="showNav" />
    <router-view :class="['animate__animated', viewAnimateClass]" />
    <Footer :background-gray="footerBackgroundGray" :absolute="footerAbsolute" />
  </div>
</template>

<script>
// @ is an alias to /src
require('@/lib/iconfont');

import Utils from '@/lib/utils.js'
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      viewAnimateClass: ''
    }
  },
  computed: {
    appLang: function() {
      return 'lang-' + this.$root.$i18n.locale;
    },
    showNav: function() {
      return this.$route.name !== "Home"
    },
    footerAbsolute: function() {
      return ['Home'].includes(this.$route.name)
    },
    footerBackgroundGray: function() {
      console.log(this.$route.name)
      return ['IndusVision', 'AiControl'].includes(this.$route.name)
    },
    routeList: function() {
      return this.$router.options.routes
    },
    activeRouteIndex: function() {
      let activeRoute = this.$route
      return this.routeList.findIndex(function(route) {
          return route.name === activeRoute.name
      })
    },
    documentTitle: function() {
      return this.$t('company.name')
    }
  },
  mounted: function() {
    document.title = this.documentTitle
  },
  beforeUpdate: function() {
    document.title = this.documentTitle
  },
  methods: {
    onMousewheel: function(e) {
      let direction = this._getFlipDirection(e)
      // 方向0不翻页，停留本页
      if(direction !== 0) {
        this._flipPage(direction)
      }      
    },
    /**
     * 计算翻页方向
     * -1向上，0为不翻，1向下
     */
    _getFlipDirection: function(e) {
      let appRef = this.$refs.app
      let scrollTop = document.documentElement.scrollTop    // 距离顶部的距离
      let windowHeight = Math.max(
          document.documentElement.clientHeight, 
          window.innerHeight
      )                                                     // 可视区域的高度
      let scrollHeight = appRef.scrollHeight                // 滚动条的总高度
      let deltaY = e.deltaY                                 // 滚轮方向，>0向下，<0向下

      if (deltaY < 0 && scrollTop === 0) {
        return -1
      }
      if (deltaY > 0 && (scrollTop + windowHeight) >= scrollHeight) {
        return 1
      }
      return 0
    },
    _flipPage: Utils._damping(function(step) {
      let routeList = this.routeList
      let activeRouteIndex = this.activeRouteIndex
      // 首页不向上翻
      if(activeRouteIndex === 0 && step < 0) {
        return
      }
      // 未页不向下翻
      if((activeRouteIndex === (routeList.length - 1)) && step > 0) {
        return    
      }
      // 跳转到新页
      let flipIndex = activeRouteIndex + step
      let flipName = routeList[flipIndex].name
      this.$router.push({name: flipName}, ()=>{
        // 翻页切换动画
        let viewAnimate = (step == -1) ? 'animate__fadeInDown' : 'animate__fadeInUp'
        this.viewAnimateClass = [viewAnimate, 'animate__slow'].join(' ')

        // animate__slow为2s，动画完成后移除动画
        setTimeout(()=> {
          this.viewAnimateClass = ''
        }, 2000)
      })
    })
  }
}
</script>

<style lang="less">
  @import "~normalize.css";
  @import "~animate.css";
  @import "./lib/common.less";

  #app {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
  }
</style>

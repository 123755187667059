<template>
  <!-- 公共底部start -->
  <footer class="g-ft h-72 md:h-40 z-50 animate__animated animate__fadeInUp"
    :class="{ 'g-ft-gray': backgroundGray, 'absolute': absolute, 'bottom-0': absolute  }">
    <div class="g-ft-hd welcome">{{ $t("contactUs") }}</div>
    <ul class="grid grid-cols-1 md:grid-cols-2 justify-between gap-4 my-2 text-base lg:text-lg">
      <li class="md:col-span-2 text-center">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#gshb_address"></use>
        </svg>
        <span class="ml-2">{{ $t("address.label") }}</span>
        <a class="" href="https://www.amap.com/place/B0FFKF50QK" target="_blank" role="address">{{
        $t("address.text") }}</a>
      </li>
      <li class="text-center md:text-left">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#gshb_jshz"></use>
        </svg>
        <span class="ml-2">{{ $t("tech.label") }}</span>
        <a class="" href="mailto:chun.yang@sensingx.cn" role="mailto">chun.yang@sensingx.cn</a>
      </li>
      <li class="text-center md:text-right">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#gshb_swhz"></use>
        </svg>
        <span class="ml-2">{{ $t("market.label") }}</span>
        <a class="" href="mailto:bowen.zhou@sensingx.cn" role="mailto">bowen.zhou@sensingx.cn</a>
      </li>
      <li class="md:col-span-2 text-center">
        <span>备案号：</span>
        <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2022031796号-1</a>
      </li>
    </ul>
  </footer>
  <!-- 公共底部start -->
</template>

<script>
export default {
  name: "Footer",
  props: {
    backgroundGray: {
      type: Boolean,
      require: false,
      default: false,
    },
    absolute: {
      type: Boolean,
      require: false,
      default: true,
    },
  },
};
</script>

<i18n>
{
  "en": {
    "contactUs": "contact us",
    "address": {
        "label": "Address:",
        "text": "206, Building 6, Artificial Intelligent Town(China), Yuhang District, Hangzhou"
    },
    "tech": {
        "label": "Technology:"
    },
    "market": {
        "label": "Marketing:"
    }
  },
  "zh": {
    "contactUs": "欢迎有意向合作伙伴同我们联系！",
    "address": {
        "label": "公司地址：",
        "text": "杭州市余杭区中国人工智能小镇6幢206室"
    },
    "tech": {
        "label": "技术合作："
    },
    "market": {
        "label": "商务合作："
    }
  }
}
</i18n>

<style lang="less" scoped>
.g-ft {
  //position: fixed;
  //z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //bottom: 0;
  //left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  &-gray {
    background: rgba(130, 157, 255, 0.1);
  }
  &-hd {
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
}
</style>

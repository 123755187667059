<template>
  <!-- 首页start -->
  <section class="relative z-10 w-screen mb-96 md:mb-0 md:h-screen">
    <HomeBackgroundVideo />
    <HomeProfile />

  </section>
  <!-- 首页end -->
</template>

<script>
// @ is an alias to /src
import HomeBackgroundVideo from '@/components/HomeBackgroundVideo.vue'
import HomeProfile from '@/components/HomeProfile.vue'

export default {
  name: 'Home',
  components: {
    HomeBackgroundVideo,
    HomeProfile
  }
}
</script>

<style lang="less" scoped>
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sensingx',
    name: 'Profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue')
  },
  {
    path: '/remote-sensing-and-gis',
    name: 'SenseGeo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SenseGeo.vue'),
    children: [
      {
        // 会被渲染在 SenseGeo 的 <router-view> 中
        path: 'building-extraction',
        name: 'SenseGeoBuildingExtraction',
        component: () => import(/* webpackChunkName: "about" */ '../views/SenseGeoBuildingExtraction.vue'),
      },
      {
        // 会被渲染在 SenseGeo 的 <router-view> 中
        path: 'land-cover',
        name: 'SenseGeoLandCover',
        component: () => import(/* webpackChunkName: "about" */ '../views/SenseGeoLandCover.vue'),
      },
      {
        // 会被渲染在 SenseGeo 的 <router-view> 中
        path: 'rs-object-detection',
        name: 'SenseGeoRsObjectDetection',
        component: () => import(/* webpackChunkName: "about" */ '../views/SenseGeoRsObjectDetection.vue'),
      }
    ]
  },
  {
    path: '/industry-vision',
    name: 'IndusVision',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/IndusVision.vue'),
    children: [
      {
        // 会被渲染在 SenseGeo 的 <router-view> 中
        path: 'foam-inserts-identification',
        name: 'IndusVisionDiscernFrothInserts',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndusVisionDiscernFrothInserts.vue'),
      },
      {
        // 会被渲染在 SenseGeo 的 <router-view> 中
        path: 'vehicle-seats-cut-pieces-identification',
        name: 'IndusVisiondiscernCarSeat',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndusVisiondiscernCarSeat.vue'),
      },
      {
        // 会被渲染在 SenseGeo 的 <router-view> 中
        path: 'abnormal-matter-detection',
        name: 'IndusVisionDetectProductionEnv',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndusVisionDetectProductionEnv.vue'),
      }
    ]
  },
  {
    path: '/uav-based-ai-control-unit',
    name: 'AiControl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AiControl.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

<template>
  <!-- 公共头部start -->
  <header class="fixed z-50 left-0 top-0 flex justify-between items-center w-screen	h-14 px-5 bg-black shadow-xl">
    <span class="inline md:hidden" @click="mobileMenuOpen = !mobileMenuOpen">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 stroke-current text-white" fill="none" viewBox="0 0 24 24"
        stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
      </svg>
    </span>
    <router-link to="/" class="flex items-center text-xl font-bold">
      <img class="w-9 h-9 mr-3" src="../assets/logo.svg" alt="$t('company.name')" />
      <span class="hidden xl:inline ">{{ $t("company.name") }}</span>
      <span class="inline md:hidden lg:inline" @click="mobileMenuOpen = false">{{ $t("company.shortName")}}</span>
    </router-link>
    <nav class="flex items-center gap-x-5">
      <ul :class="[mobileMenuOpen ? 'nav-list-mobile' : 'nav-list', 'animate__animated animate__fadeInLeft']"
        @click="handleSelectNav">
        <!--
        <router-link v-if="mobileMenuOpen" class="hover:text-blue-400" active-class="nav-item-active" tag="li" to="/">{{
        $t("home")
        }}</router-link>
        -->
        <router-link class="hover:text-blue-400" active-class="nav-item-active" tag="li" to="/sensingx">{{
          $t("company.shortName")
          }}</router-link>
        <router-link class="hover:text-blue-400" active-class="nav-item-active" tag="li" to="/remote-sensing-and-gis">{{
          $t("business.senseGeo.name") }}</router-link>
        <router-link class="hover:text-blue-400" active-class="nav-item-active" tag="li" to="/industry-vision">{{
          $t("business.IndustryVision.name") }}</router-link>
        <router-link class="hover:text-blue-400" active-class="nav-item-active" tag="li"
          to="/uav-based-ai-control-unit">{{
          $t("business.aiControl.name") }}</router-link>
      </ul>
      <button class="rounded-full border border-gray-500 h-6 w-6" @click="handleChangeLocale(langNotUse.key)">
        {{ langNotUse.text }}
      </button>
    </nav>
  </header>
  <!-- 公共头部end -->
</template>

<script>
export default {
  name: "Header",
  props: {
    showNav: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      mobileMenuOpen: false,
      langs: [
        {
          key: "zh",
          text: "中",
        },
        {
          key: "en",
          text: "En",
        },
      ],
    };
  },
  computed: {
    // 计算属性的 getter
    langUse: function() {
      // `this` 指向 vm 实例
      let lang = this.langs.find((lang) => lang.key == this.$root.$i18n.locale);
      return lang;
    },
    langNotUse: function() {
      let lang = this.langs.find(
        (lang) => lang.key !== this.$root.$i18n.locale
      );
      return lang;
    },
  },
  methods: {
    /**
     * 切换i8n语言版本，保持到localStorage，浏览器刷新保留状态
     */
    handleChangeLocale: function (locale) {
      localStorage.setItem("locale", locale);
      this.$root.$i18n.locale = locale;
    },
    handleSelectNav: function(e) {
      if (e.target.nodeName === 'LI') {
        this.mobileMenuOpen = false
      }
    }
  },
};
</script>

<style>
  .nav-list {
    @apply hidden md:flex gap-x-5 text-sm cursor-pointer;
  }
  .nav-list-mobile {
    @apply fixed top-14 bottom-0 left-0 w-3/4 list-none pl-6 bg-black text-base leading-10;
  }
  .nav-item-active {
    @apply text-blue-400 border-b border-blue-400 border-solid;
  }
</style>

<template>
  <section class="relative top-20 flex flex-col justify-center items-center w-screen">
    <nav class="flex flex-col md:flex-row gap-8 justify-center">
      <router-link tag="div" to="/industry-vision" class="business-item animate__animated  animate__backInLeft">
        <figure class="bg-black	md:bg-transparent md:w-56 lg:w-auto m-1">
          <figcaption class="business-name">
            {{ $t("business.IndustryVision.name") }}
          </figcaption>
          <img class="w-full" src="../assets/home/h-indus-vision.jpg" :alt="$t('business.IndustryVision.name')" />
        </figure>
      </router-link>
      <router-link tag="div" to="/remote-sensing-and-gis"
        class="business-item animate__animated animate__backInDown animate__slow">
        <figure class="bg-black	md:bg-transparent md:w-52 lg:w-auto m-1">
          <figcaption class="business-name">
            {{ $t("business.senseGeo.name") }}
          </figcaption>
          <img class="w-full" src="../assets/home/h-sense-geo.jpg" :alt="$t('business.senseGeo.name')" />
        </figure>
      </router-link>
      <router-link tag="div" to="/uav-based-ai-control-unit"
        class="business-item animate__animated animate__backInRight animate__slower">
        <figure class="bg-black	md:bg-transparent md:w-56 lg:w-auto m-1">
          <figcaption class="business-name">
            {{ $t("business.aiControl.name") }}
          </figcaption>
          <img class="w-full" src="../assets/home/h-ai-control.jpg" :alt="$t('business.aiControl.name')" />
        </figure>
      </router-link>
    </nav>
    <div class="hidden md:block mt-2 text-center">
      <img class="h-20" src="../assets/home/h-business-arrow.png" alt="" />
    </div>
    <div class="mt-2 text-center font-semibold animate__animated animate__rubberBand">
      <img class="h-20" src="../assets/home/sen-sing-x.svg" role="$t('company.name')" />
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeProfile",
};
</script>

<style lang="less" scoped>
  
  .business-item {
    @apply rounded ring-1 ring-gray-500 hover:ring-blue-300;
  }
  .business-name {
    @apply h-12 m-0 font-semibold text-center text-xl md:text-base lg:text-xl leading-10;
  }

</style>

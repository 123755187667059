<template>
  <div class="absolute z-0">
    <video class="w-screen h-screen object-fill" autoplay loop muted controlslist="nodownload">
      <source src="../assets/home/h-bg-video.mp4" type="video/mp4">
      {{ $t('browserNotSupportVideo') }}
    </video>
  </div>
</template>

<script>
export default {
    name: "HomeBackgroundVideo"
}
</script>

<style lang="less" scoped>
</style>
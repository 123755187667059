import './assets/tailwind.css'

import App from './App.vue'
import Vue from 'vue'
import i18n from './i18n'
import router from './router'

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

//i18n.locale = 'en'
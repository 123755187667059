    /**
     * 阻尼函数，使用单位时间内滚动累积次数达到目标触发
     * @param func 回调函数，达到目标触发
     * @param wait 限定单位时间
     * @param countLimit 单位时间内累积次数，达到则触发回调函数
     */
const _damping = function(func, wait=1000, countLimit = 5) {
        let lastTime = new Date()
        let count = 0
        return function() {
          let context = this
          let arg = arguments
          let nowTime = new Date()
          if((nowTime - lastTime) < wait) {
            if(count > countLimit) {          // 单位时间达到累积次数，触发函数
              func.apply(context, arg)
              count = 0
              lastTime = nowTime
            }else {                          // 单位时间内未达到次数，继续累积
              count++
            }
          }else {                             // 超时重置
              count = 0
              lastTime = nowTime
          }
        }
  }

  export default {
    _damping
  }